import React, { ChangeEvent } from "react";
import { AppConsumer, AppContext } from "../components/AppContext";
import { IGenericProps } from "../interfaces/generics";
import { getNextRoute } from "../util/RouteHelper";
import {
    Company,
    Customer,
    CustomerFields,
    CustomerFieldsLabels,
    CustomerMaxLength, Reason,
    ReasonOption
} from "../interfaces/models";
import api from "../requests/api";
import { toast } from "react-toastify";
import { getTranslations, validateCustomer, ValidationError, EValidationErr, EPropTypes } from "../util/CustomerUtils";
import { toSnakeCase } from "../util/StringUtils";
import Spinner from "../components/Spinner";
import withTracker from "../util/withTracker";
import TrackedButton from "../components/TrackedButton";
import withRouteProps from "../util/withRouteProps";

interface IClientFormState {
    visibleFields?: CustomerFields,
    obligatoryFields?: CustomerFields,
    dirty: CustomerFields & { reasons: { [id: number]: boolean | undefined } },
    fieldLabels?: CustomerFieldsLabels,
    maxLength: CustomerMaxLength,
    customer: Customer,
    reason?: string,
    fileUploading?: boolean,
    isLoadingForm?: boolean,
    fileName?: string
}

class ClientForm extends React.Component<IGenericProps, IClientFormState> {
    constructor(props: IGenericProps) {
        super(props);
        this.goBack = this.goBack.bind(this);
        this.next = this.next.bind(this);
        this.state = {
            isLoadingForm: true,
            customer: {},
            maxLength: {},
            dirty: { reasons: {} },
            reason: '',
            fileUploading: false,
            fileName: ''
        }
    }

    goBack() {
        this.props.history.goBack();
    }

    next() {
        this.props.history.push(getNextRoute(this.context));
        // TODO pegarle a una API para validar antes de pasar a la siguiente vista
        // (por las dudas, por si las validaciones de front y de back no coinciden)
    }

    async componentDidMount() {
        const { t } = this.context;
        const ctxCompany = this.context.state.company;
        const { selectedSchedule } = this.context.state;
        if (!selectedSchedule || !ctxCompany || ctxCompany.name !== this.props.params.companyName) {
            console.error("Redirecting since no schedule or company was selected in form");
            toast.error(t("ClientForm.TOAST_NOT_SELECTED", {
                what: !this.context.state.company ? t("ClientForm.TOAST_COMPANY") : t("ClientForm.TOAST_SCHEDULE")
            }));
            this.props.history.push(`/company/${this.props.params.companyName || ''}`);
            return;
        }


        const [{ data: visibleFields },
            { data: obligatoryFields },
            { data: fieldLabels },
            { data: maxLength }] = (await Promise.all([
                api.customerAttributes().getVisible(selectedSchedule),
                api.customerAttributes().getObligatory(selectedSchedule),
                api.customerAttributes().getFields(this.context.state.company as Company),
                api.customerAttributes().getMaxLength(this.context.state.company as Company),
            ])) as [{ data: CustomerFields }, { data: CustomerFields }, { data: CustomerFieldsLabels }, { data: CustomerMaxLength }]

        visibleFields.firstName = obligatoryFields.firstName = true;
        visibleFields.lastName = obligatoryFields.lastName = true;
        this.context.actions!.setObligatoryFields(obligatoryFields);
        this.context.actions!.setReasonChosenOptions([]);


        const customer = { ...this.context.state.customer };
        const reason = this.context.state.reason || '';

        this.setState({
            visibleFields,
            obligatoryFields,
            fieldLabels,
            maxLength,
            customer,
            reason,
            isLoadingForm: false
        });

    }

    componentDidUpdate() {
        const ctxCompany = this.context.state.company;
        if (ctxCompany?.name !== this.props.params.companyName) {
            console.warn("Company changed, redirecting");
            this.props.history.push(`/company/${this.props.params.companyName || ''}`);
            return;
        }
    }

    fileChanged(ev: ChangeEvent<HTMLInputElement>) {
        const MB = 1 * 1024 * 1024;
        const target = ev.target as HTMLInputElement;
        const file = target.files?.[0];
        const extn = file?.name.split(".").slice(1).pop();
        const { t } = this.context;
        const allowedExtensions = ["pdf", "doc", "docx", "txt", "rtf", "odt", "html", "xml", "jpg", "jpeg", "png", "gif", "bmp", "tiff", "psd", "svg", "xls", "xlsx", "mp4", "avi", "mov", "mkv", "wmv", "flv", "mpeg", "3gp", "ppt"];

        if (!file) return;
        if (target.value.length === 0) {
            // El usuario tocó cancelar
            return;
        }

        if (file.size > 2 * MB || !extn || !allowedExtensions.includes(extn.toLowerCase())) {
            target.value = '';
            toast.error(t("ClientForm.TOAST_OVERSIZED_FILE_OR_NOT_VALID_FILE"));
            return;
        }
        this.uploadFile(file, target);
    }

    async uploadFile(file: File, target: HTMLInputElement) {
        this.setState({ fileUploading: true });

        const formData = new FormData();
        formData.append("file", file);

        let res;
        const { t } = this.context;
        try {
            res = await api.appointment().uploadFile(formData, this.context.state.company?.name as string);
        } catch (e) {
            console.error("Error uploading file", e);
            this.setState({ fileUploading: false });
            toast.error(t("ClientForm.TOAST_FAILED_TO_UPLOAD_FILE"));
            target.value = '';
            return;
        }

        this.setState({ fileName: file.name });

        this.context.actions!.setRandomFileId(res.data.randomId);

        toast.success(t("ClientForm.TOAST_SUCCEEDED_TO_UPLOAD_FILE"));
        this.setState({ fileUploading: false });
    }

    getAutocompleteOpt = (prop: keyof Customer) => {
        const opts: { [key in keyof Customer]: string } = {
            phone: 'tel-local',
            email: 'email',
            firstName: 'given-name',
            lastName: 'family-name'
        }
        return opts[prop] || 'on';
    }

    reasonChanged = (r: Reason, ev: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = ev.target;
        let { dirty, reason } = this.state
        dirty.reasons[r.id] = true;
        reason = value

        this.setState({
            reason,
            dirty
        });
        this.context.actions!.setReason(reason);
    }

    propsChanged = (ev: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = ev.target;
        const { customer, dirty } = this.state
        dirty[name as keyof Customer] = true;
        customer[name as keyof Customer] = value;

        this.setState({
            customer,
            dirty
        });
        this.context.actions!.selectCustomer(customer);
    }

    getErrorText = (errors: ValidationError[], field: keyof Customer) => {
        const { t } = this.context;
        if (!this.state.dirty[field]) return '';
        const err = errors.find(err => err.target === field);
        if (!err)
            return '';
        if (err.key === EValidationErr.missing_field)
            return t("ClientForm.REQUIRED");
        switch (err?.dataType) {
            case EPropTypes.number:
                return t("ClientForm.INVALID_NUMBER");
            case EPropTypes.text:
                return t("ClientForm.INVALID_TEXT");
            case EPropTypes.email:
                return t("ClientForm.INVALID_EMAIL");
            case EPropTypes.alphanumeric:
                return t("ClientForm.INVALID_ALPHANUMERIC");
            default:
                return t("ClientForm.INVALID");
        }
    }

    getReasonErrorText = (reason: Reason) => {
        const { t } = this.context;
        if (!reason.obligatory) return '';
        if (!this.state.dirty.reasons[reason.id]) return '';
        return this.chosenOption(reason) ? '' : t("ClientForm.REQUIRED");
    }

    fileHasError = () => {
        const { selectedSchedule } = this.context.state;
        return this.state.fileUploading || (selectedSchedule?.fileRequired && !this.state.fileName);
    }

    handleOptionChange = (option: ReasonOption, reason: Reason) => {
        let { reasonChosenOptions } = this.context.state;
        reasonChosenOptions = reasonChosenOptions ?? [];

        const { dirty } = this.state;
        dirty.reasons[reason.id] = true;

        if (reason.multipleOptionsAllowed) {
            if (reasonChosenOptions.includes(option)) {
                reasonChosenOptions = reasonChosenOptions.filter(item => item !== option);
            } else {
                reasonChosenOptions.push(option);
            }
        } else {
            const idxOptionModified = reasonChosenOptions.findIndex(opt => reason.options.includes(opt));
            if (idxOptionModified < 0) {
                reasonChosenOptions.push(option);
            } else {
                reasonChosenOptions[idxOptionModified] = option
            }
        }
        this.context.actions!.setReasonChosenOptions(reasonChosenOptions);
    }


    render() {

        if (!this.context.state.selectedSchedule) return <></>;

        const {
            visibleFields,
            obligatoryFields,
            fieldLabels,
            customer,
            reason,
            isLoadingForm,
            fileUploading,
            fileName,
            maxLength
        } = this.state
        const { selectedSchedule, reasonChosenOptions } = this.context.state;
        const { t } = this.context;

        const fields = getTranslations(visibleFields, fieldLabels, t);
        const errors = customer ? validateCustomer(customer, obligatoryFields, visibleFields) : [];

        const { reasons } = selectedSchedule;

        return (
            <AppConsumer>
                {({ t }) => (
                    <>
                        <div className="form-section form-card__content">
                            <Spinner loading={isLoadingForm} color="#DCDCDC" scale={.4} style={{
                                margin: "0 auto"
                            }} />
                            {
                                !isLoadingForm &&
                                <form className="form-section__form">
                                    <>
                                        {
                                            fields.map(field => {
                                                const error = this.getErrorText(errors, field.key);
                                                return (
                                                    <div className={'form-section__input-container'} key={field.key}>
                                                        <input type="text" className={`form-section__form-input form-section__form-input${error ? '-error' : ''}`}
                                                            onChange={this.propsChanged}
                                                            name={field.key}
                                                            maxLength={maxLength[toSnakeCase(field.key)]}
                                                            autoComplete={this.getAutocompleteOpt(field.key)}
                                                            value={customer[field.key] || ''} />
                                                        <span className={`${!!customer[field.key] ? 'form-section__floating-label-dirty' : ''} form-section__floating-label`}>
                                                            {field.showName + (obligatoryFields?.[field.key] ? '*' : '')}
                                                        </span>
                                                        <div className="form-section__text-error">
                                                            {error}
                                                        </div>
                                                    </div>
                                                )
                                            })
                                        }
                                        {
                                            <div className={'form-section__reason-container'}>
                                                {reasons.map(actualReason => {
                                                    const reasonError = this.getReasonErrorText(actualReason);
                                                    return <div key={actualReason.id}>
                                                        {
                                                            (actualReason.options && actualReason.options.length > 0) && (
                                                                <div>
                                                                    <strong>{actualReason.message + (actualReason.obligatory ? '*' : '')}</strong>
                                                                    <ul>
                                                                        {actualReason.options.map(option => (
                                                                            <li key={option.id}>
                                                                                <label>{option.reasonOption}</label>
                                                                                <input
                                                                                    className={`form-section__form-option${reasonError ? '-error' : ''}`}
                                                                                    type={actualReason.multipleOptionsAllowed ? 'checkbox' : 'radio'}
                                                                                    value={option.reasonOption}
                                                                                    checked={reasonChosenOptions?.includes(option)}
                                                                                    onChange={() => this.handleOptionChange(option, actualReason)}
                                                                                />
                                                                            </li>
                                                                        ))}
                                                                    </ul>
                                                                    <div className="form-section__text-error">
                                                                        {reasonError}
                                                                    </div>
                                                                </div>
                                                            )}
                                                        {(!actualReason.options || actualReason.options.length === 0) &&
                                                            <div className={'form-section__input-container form-section__input-container-reason'}>
                                                                <input type="text"
                                                                    className={`form-section__form-input form-section__form-input${reasonError ? '-error' : ''}`}
                                                                    onChange={this.reasonChanged.bind(this, actualReason)}
                                                                    name={"reason"}
                                                                    autoComplete="off"
                                                                    value={reason} />
                                                                <span
                                                                    className={`${!!reason ? 'form-section__floating-label-dirty' : ''} form-section__floating-label`}>
                                                                    {actualReason.message + (actualReason.obligatory ? '*' : '')}
                                                                </span>
                                                                <div className="form-section__text-error">
                                                                    {reasonError}
                                                                </div>
                                                            </div>
                                                        }
                                                    </div>
                                                })}
                                            </div>
                                        }
                                        {
                                            selectedSchedule.allowFileUpload && <>
                                                <div className={'form-section__file-container'}>
                                                    <label htmlFor="fileInput"
                                                        className="form-section__form-fileupload text-center">
                                                        <i className="bi bi-file-earmark-arrow-up"></i> {(fileName || selectedSchedule.fileUploadLabel || t("ClientForm.ATTACH_FILE")) + (selectedSchedule.fileRequired ? "*" : "")}
                                                        <Spinner loading={fileUploading} color="#DCDCDC" scale={.4} style={{
                                                            marginLeft: "auto"
                                                        }} />
                                                    </label>
                                                    <input id="fileInput" style={{ visibility: "hidden" }} onChange={ev => this.fileChanged(ev)} type="file"></input>
                                                </div>
                                            </>
                                        }
                                    </>

                                </form>
                            }

                        </div>
                        <div className="list-selection__buttons">
                            <TrackedButton
                                id="BtnBackScheduleFirst"
                                disabled={isLoadingForm}
                                className="list-selection__button list-selection__button-blue form-card__button form-card__button--previous"
                                onClick={this.goBack}> {t("ClientForm.PREVIOUS")}
                            </TrackedButton>
                            <TrackedButton
                                id="next_clientform"
                                className="list-selection__button list-selection__button-blue form-card__button form-card__button--next"
                                onClick={this.next}
                                disabled={!customer || errors.length > 0 || (this.notChosenObligatoryReason(this.context.state.selectedSchedule?.reasons || [])) || this.fileHasError() || isLoadingForm}>{t("ClientForm.NEXT")}
                            </TrackedButton>
                        </div>
                    </>
                )}
            </AppConsumer>
        )
    }

    static contextType = AppContext
    declare context: React.ContextType<typeof AppContext>;

    private notChosenObligatoryReason(reasons: Reason[]) {
        return !reasons.every(r => this.chosenOption(r));
    }

    private chosenOption(r: Reason) {
        if (r.obligatory && r.options?.length > 0) {
            return this.hasChosenTheObligatoryOption(r);
        } else if (r.obligatory) {
            return !!this.state.reason;
        }
        return true;
    }

    private hasChosenTheObligatoryOption(r: Reason) {
        return r.options.some(
            opt => this.context.state.reasonChosenOptions?.includes(opt));
    }

}

export default withRouteProps(withTracker(ClientForm));
